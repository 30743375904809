.drawerItemActive {
  /* background-color: rgba(49, 148,218, 0.46);  latest   */
  background-color: #eaeaea;

  display: flex;
  width: 240px;

  border-radius: 10px;

  font-family: "Roboto";
}
.divContainer {
  background-color: yellow;
}
